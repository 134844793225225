


















import { defineComponent, reactive } from "@vue/composition-api";

export default defineComponent({
  props: {
    value: {
      type: Array,
      required: false,
      default: () => [],
    },
    consents: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  setup(props, { root }) {
    const model = reactive({
      consentIds: props.value,
    });
    const rules = {
      required: [(v: string) => !!v || `${root.$t("layout.misc.required")}`],
    };

    return { model, rules };
  },
});
